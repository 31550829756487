export const colorsV2 = {
  accent100: '#EF692F',

  // Disabled
  grey200: '#21262D',
  grey180: '#313A44',
  grey160: '#434E5B',
  grey140: '#5A6877',
  grey120: '#6D7D8D',
  grey100: '#8594A3',
  grey80: '#A1ADB9',
  grey70: '#5E6C84',
  grey60: '#B8C1CC',
  grey50: '#D5D7E1',
  grey40: '#D2D8DF',
  grey20: '#E3E7ED',
  grey10: '#F2F4F7',
  grey5: '#F9FAFB',

  // Black
  black100: '#242938',
  black90: '#373C4B',
  black80: '#505460',
  black70: '#6F727B',
  black60: '#7C7F88',
  black50: '#95979F',
  black40: '#A7A9AF',
  black30: '#BDBEC3',
  black20: '#D3D4D7',
  black10: '#E9EAEB',
  black5: '#F4F4F5',

  blackShadow1: 'rgba(0, 0, 0, 0.01)',
  blackShadow2: 'rgba(0, 0, 0, 0.02)',
  blackShadow3: 'rgba(0, 0, 0, 0.03)',
  blackShadow4: 'rgba(0, 0, 0, 0.04)',
  blackShadow5: 'rgba(0, 0, 0, 0.05)',
  blackShadow6: 'rgba(0, 0, 0, 0.06)',
  blackShadow7: 'rgba(0, 0, 0, 0.07)',
  blackShadow8: 'rgba(0, 0, 0, 0.08)',

  // White
  white100: '#FFFFFF',
  white90: 'rgba(255, 255, 255, 0.9)',
  white80: 'rgba(255, 255, 255, 0.8)',
  white70: 'rgba(255, 255, 255, 0.7)',
  white60: 'rgba(255, 255, 255, 0.6)',
  white50: 'rgba(255, 255, 255, 0.5)',
  white40: 'rgba(255, 255, 255, 0.4)',
  white30: 'rgba(255, 255, 255, 0.3)',
  white20: 'rgba(255, 255, 255, 0.2)',
  white10: 'rgba(255, 255, 255, 0.1)',
  white5: 'rgba(255, 255, 255, 0.05)',

  // Gray
  gray180: '#313A44',
  gray160: '#434E5B',
  gray140: '#5A6877',
  gray120: '#6D7D8D',
  gray100: '#8594A3',
  gray80: '#A1ADB9',
  gray70: '#5E6C84',
  gray60: '#B8C1CC',
  gray50: '#D5D7E1',
  gray40: '#D2D8DF',
  gray20: '#E3E7ED',
  gray10: '#F2F4F7',
  gray5: '#F9FAFB',

  // Light Gray
  lightGray180: '#CCD9E1',
  lightGray160: '#D4DFE6',
  lightGray140: '#DCE5EA',
  lightGray120: '#E3EAEF',
  lightGray100: '#F3F6F8',
  lightGray80: '#F3F6F8',
  lightGray60: '#F5F8F9',
  lightGray40: '#F8FAFB',
  lightGray20: '#FDFDFE',
  lightGray10: '#FCFCFD',
  lightGray5: '#FEFFFF',

  // Primary
  primary180: '#5A0C0C',
  primary160: '#7E1111',
  primary140: '#971717',
  primary120: '#B81E1E',
  primary100: '#D42525',
  primary80: '#E14C4C',
  primary60: '#EA7575',
  primary40: '#F2A1A1',
  primary20: '#FAD6D6',
  primary10: '#FDEDED',
  primary5: '#FEF6F6',

  // Pink
  pink180: '#DA4B67',
  pink160: '#E35B75',
  pink140: '#F2758D',
  pink120: '#F595A8',
  pink100: '#F8B6C3',
  pink80: '#F9C5CF',
  pink60: '#FBD3DB',
  pink40: '#FCE2E7',
  pink20: '#FEF0F3',
  pink10: '#FEF8F9',
  pink5: '#FFFBFC',

  // Yellow
  yellowReadable: '#DD7800',
  yellow180: '#8F4E00',
  yellow160: '#A75B00',
  yellow140: '#BF6800',
  yellow120: '#DD7800',
  yellow100: '#FF991F',
  yellow80: '#FFAD4C',
  yellow60: '#FFC279',
  yellow40: '#FFD6A5',
  yellow20: '#FFEBD2',
  yellow10: '#FFF5E9',
  yellow5: '#FFFAF4',

  // Purple
  purple180: '#211056',
  purple160: '#2C1773',
  purple140: '#3D209D',
  purple120: '#4927B9',
  purple100: '#5B37D2',
  purple80: '#7A5CDB',
  purple60: '#9880E5',
  purple40: '#BAA9EF',
  purple20: '#D5CBF6',
  purple10: '#F1EDFC',
  purple5: '#F8F6FE',

  // Green
  green180: '#1D3A23',
  green160: '#244C2C',
  green140: '#2E6639',
  green120: '#347F43',
  green100: '#329546',
  green80: '#4AB05E',
  green60: '#6CC67E',
  green40: '#96D9A3',
  green20: '#CAECD1',
  green10: '#ECF9EE',
  green5: '#F3FBF5',

  // Red
  red180: '#67210A',
  red160: '#872B0D',
  red140: '#AB3812',
  red120: '#C64115',
  red100: '#E24B19',
  red80: '#EC6C41',
  red60: '#F19374',
  red40: '#F7B7A1',
  red20: '#FBDAD0',
  red10: '#FEF0EC',
  red5: '#FEF8F5',

  // Blue
  blue180: '#0F2657',
  blue160: '#143171',
  blue140: '#1B4193',
  blue120: '#2250B4',
  blue100: '#2B61D4',
  blue80: '#5480DE',
  blue60: '#7D9FE8',
  blue40: '#A7BFF1',
  blue20: '#CAD8F7',
  blue10: '#EDF2FD',
  blue5: '#F6F8FE',

  // Cabaret
  cabaret0: '#FFF7F5',
  cabaret100: '#FEECE7',
  cabaret200: '#FEDCD2',
  cabaret500: '#F16237',
  cabaret700: '#C54B26',
  cabaret1000: '#79311B',

  // Deepsky
  deepsky0: '#F5FCFF',
  deepsky100: '#DCF5FE',
  deepsky200: '#98E0F9',
  deepsky600: '#27A3CD',
  deepsky700: '#1B91B8',
  deepsky900: '#0E6987',

  // Paper
  paper: '#FFF9F1',

  // Neutral
  neutral200: '#21262D',
  neutral180: '#313A44',
  neutral160: '#434E5B',
  neutral140: '#5A6877',
  neutral120: '#6D7D8D',
  neutral100: '#8594A3',
  neutral80: '#A1ADB9',
  neutral70: '#5E6C84',
  neutral60: '#B8C1CC',
  neutral50: '#D5D7E1',
  neutral40: '#D2D8DF',
  neutral20: '#E3E7ED',
  neutral10: '#F2F4F7',
  neutral5: '#F9FAFB',

  // Gradient
  gradientGreen: 'linear-gradient(90deg, #358756 0%, #4BB956 100%)',
  gradientBlue: 'linear-gradient(90deg, #2A7CD9 0%, #329EED 99.95%)',

  // Black transparent (BT) -> To be replace with Black transparent below
  bt10: 'rgba(0, 0, 0, 0.1)',
  bt20: 'rgba(0, 0, 0, 0.2)',
  bt30: 'rgba(0, 0, 0, 0.3)',
  bt40: 'rgba(0, 0, 0, 0.4)',
  bt50: 'rgba(0, 0, 0, 0.5)',
  bt60: 'rgba(0, 0, 0, 0.6)',
  bt70: 'rgba(0, 0, 0, 0.7)',

  // Black transparent (Black Alpha)
  blackTransparent100: '#000000',
  blackTransparent90: 'rgba(0, 0, 0, 0.90)',
  blackTransparent80: 'rgba(0, 0, 0, 0.80)',
  blackTransparent70: 'rgba(0, 0, 0, 0.70)',
  blackTransparent60: 'rgba(0, 0, 0, 0.60)',
  blackTransparent50: 'rgba(0, 0, 0, 0.50)',
  blackTransparent40: 'rgba(0, 0, 0, 0.40)',
  blackTransparent30: 'rgba(0, 0, 0, 0.30)',
  blackTransparent20: 'rgba(0, 0, 0, 0.20)',
  blackTransparent10: 'rgba(0, 0, 0, 0.10)',
  blackTransparent5: 'rgba(0, 0, 0, 0.05)',

  // Neutral transparent
  neutralTransparent100: '#313A44',
  neutralTransparent90: 'rgba(52, 65, 80, 0.90)',
  neutralTransparent80: 'rgba(42, 57, 72, 0.80)',
  neutralTransparent70: 'rgba(45, 62, 81, 0.70)',
  neutralTransparent60: 'rgba(50, 69, 90, 0.60)',
  neutralTransparent50: 'rgba(39, 59, 82, 0.50)',
  neutralTransparent40: 'rgba(69, 92, 118, 0.40)',
  neutralTransparent30: 'rgba(117, 141, 168, 0.30)',
  neutralTransparent20: 'rgba(103, 131, 163, 0.20)',
  neutralTransparent10: 'rgba(110, 138, 168, 0.10)',
  neutralTransparent5: 'rgba(115, 138, 163, 0.05)',

  // Overlay Drawer
  overlayDrawer70: 'rgba(45, 62, 81, 0.7)',

  // Custom colors
  gradientRed: 'linear-gradient(95deg, #d42525 18.07%, #eb6464 81.04%)',
  gradientRed28To82: 'linear-gradient(297deg, #df5757 28.26%, #f18989 82.75%)',
  gradientWhite013_6To90:
    'linear-gradient(93deg, rgba(255, 255, 255, 0.13) 6.01%, rgba(255, 255, 255, 0.25) 90.83%)',
  gradientWhite021_6To90:
    'linear-gradient(93deg, rgba(255, 255, 255, 0.21) 6.01%, rgba(255, 255, 255, 0.39) 90.83%)',
  rgbaGray01: 'rgba(163, 182, 205, 0.60)',
  rgbaGray02: 'rgba(59, 84, 111, 0.14)',

  neutralPrimary: '#313A44',
  neutralSecondary: '#6D7D8D',
  neutralDim: '#8594A3',
  neutralSubtle: '#5A6877',
  neutralStress: '#21262D',
  brandPrimary: '#D42525',
  infoPrimary: '#2B52D4',
  warningPrimary: '#FF991F',

  newYear2: '#FED624',
  newYear5: '#ED6602',

  aiFeatureDeep : '#0077A3',
};
